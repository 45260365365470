import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, AccountInfo, Configuration, BrowserAuthOptions, ProtocolMode, InteractionRequiredAuthError } from "@azure/msal-browser";
import { StorageItems, AuthScheme } from "../interface";

/**
 * class AzureHelper
 */
export class AzureHelper {
    static msalInstance: any;
    static accounts: AccountInfo;
    static tenant: string = "";
    static storage: any;
    static config: any;
    static timeout: any;
    static logOutTimeOut: any;
    static loginRequest: any = {
        scopes: [],
        forceRefresh: true
    };
    static signOutTime = 0;
    static eventsList = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
        "keydown",
    ];

    // Initializing default values
    static init(config: any) {
        AzureHelper.config = config;
        AzureHelper.storage = window[config.storage];
        AzureHelper.updateConfig();
    }
    // updating config values
    static updateConfig() {

        const authType = AzureHelper.config.authType;
        const model = AzureHelper.config.authModel;

        const config: BrowserAuthOptions = {
            clientId: model.clientId,
            authority: authType == AuthScheme.oidc ? model.authority : `https://${model.b2cName}.b2clogin.com/${model.b2cName}.onmicrosoft.com/b2c_1a_${AzureHelper.getTenant()}_rp`, // b2c_1_susi - test config -- b2c_1a_${AzureHelper.getTenant()}_rp
            knownAuthorities: [authType == AuthScheme.oidc ? AzureHelper.getAuthorityHostname(model.authority) : `${model.b2cName}.b2clogin.com`],
            redirectUri: model.redirectUrl,
            postLogoutRedirectUri: model.postLogoutRedirectUrl,
            navigateToLoginRequestUrl: true,
            protocolMode: authType == AuthScheme.oidc ? ProtocolMode.OIDC : ProtocolMode.AAD
        };

        const msalConfig: Configuration = {
            auth: config, // This is the base configuration for access azure 
            cache: {
                cacheLocation: AzureHelper.config.storage, // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        }
        if ( authType == AuthScheme.ability ) {
            AzureHelper.loginRequest.scopes = [model.scope, "openid", "profile", "offline_access"];
        } else {
            AzureHelper.loginRequest.scopes = [model.scope];
        } 
        AzureHelper.msalInstance = new PublicClientApplication(msalConfig);


        StorageItems.Token = `token:${config?.authority}:${config?.clientId}`; 
        StorageItems.ExpiredON = `expireon:${config?.authority}:${config?.clientId}`;
        StorageItems.LoggedInUser = `userinfo:${config?.authority}:${config?.clientId}`;
        StorageItems.IdToken = `id_token:${config?.authority}:${config?.clientId}`;

        AzureHelper.msalInstance.addEventCallback((event: EventMessage) => { // Listen msal-browser events
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) { // Check login state is success
                const payload = event.payload as AuthenticationResult;
                AzureHelper.setStorage(payload)
                const account = payload.account;
                AzureHelper.msalInstance.setActiveAccount(account);
            }
        }); 

    }

    // Get knownAuthority fro authority 
    static getAuthorityHostname(url: string) {
        if ( !url ) return "";
        return new URL(url).hostname;
    }

    // adding storage 
    static setStorage(data) {
        let loggedInUser = data.account
            ? { name: data.account?.idTokenClaims?.name, email: data.account?.idTokenClaims?.email }
            : null;
        AzureHelper.storage.setItem(StorageItems.LoggedInUser, JSON.stringify(loggedInUser));
        AzureHelper.storage.setItem(StorageItems.ShowLanding, JSON.stringify(true));
        AzureHelper.storage.setItem(StorageItems.Token, data.accessToken);
        AzureHelper.storage.setItem(StorageItems.IdToken, data.idToken);
        AzureHelper.storage.setItem(StorageItems.ExpiredON, data.expiresOn?.getTime() + "");
        if ( AzureHelper.config.onUpdateToken )
            AzureHelper.config.onUpdateToken(data.accessToken);
        AzureHelper.watchTimeout(data.expiresOn.getTime());
        if ( AzureHelper.config.keepLiveSession != undefined && !AzureHelper.config.keepLiveSession ) {
            if ( AzureHelper.config.timeOutSeconds ) {
                AzureHelper.signOutTime = AzureHelper.config.timeOutSeconds * 1000;
                AzureHelper.eventsList.map((event) => {
                    document.addEventListener(event, () => {
                        AzureHelper.startTimeOut();
                    });
                }, AzureHelper.signOutTime);
                AzureHelper.startTimeOut();
            }
        }
    }

    static startTimeOut() {
        AzureHelper.clearLogoutTimer();
        AzureHelper.logOutTimeOut = setTimeout(() => {
            AzureHelper.clearLogoutTimer();
            if ( !AzureHelper.config.onBeforeLogout ) {
                AzureHelper.logOut();
                return;
            } 
            AzureHelper.config.onBeforeLogout();
        }, AzureHelper.signOutTime);
    }

    static clearLogoutTimer() {
        clearTimeout(AzureHelper.logOutTimeOut);
        AzureHelper.logOutTimeOut = null;
    }

    static keepLoginSession() {
        AzureHelper.startTimeOut();
    }

    static destroy() {
        AzureHelper.eventsList.map((event) => {
            document.removeEventListener(event, () => {
                clearTimeout(AzureHelper.logOutTimeOut);
            });
            clearTimeout(AzureHelper.logOutTimeOut);
        });
        AzureHelper.logOutTimeOut = null;
    }

    // Check timout 
    static watchTimeout(expiresOn: number) {
        clearTimeout(AzureHelper.timeout);
        AzureHelper.timeout = null;
        let config = AzureHelper.config.authModel;
        const tokenRenewalOffsetSeconds = config.tokenRenewalOffsetSeconds ? config.tokenRenewalOffsetSeconds * 1000 : 300000;
        let currentDate = new Date();
        let ExpiredON = expiresOn - currentDate.getTime() - tokenRenewalOffsetSeconds;
        AzureHelper.timeout = setTimeout(() => {
            if ( AzureHelper.config.onTokenExpriry )
                AzureHelper.config.onTokenExpriry(true);
            AzureHelper.getRefreshAccessToken();
        }, ExpiredON);
    }

    // get account information 
    static async getAccount() {
        await AzureHelper.msalInstance.handleRedirectPromise().then((resp: any) => {
            if (resp !== null) {
                AzureHelper.accounts = resp.account;
                AzureHelper.msalInstance.setActiveAccount(resp.account);
            } else {
                const accounts = AzureHelper.msalInstance.getAllAccounts();
                if (accounts.length > 0) {
                    AzureHelper.accounts = accounts[0];
                    AzureHelper.msalInstance.setActiveAccount(AzureHelper.accounts);
                }
            }
        }).catch((error) => {
            console.warn(error);
        });
        return AzureHelper.accounts;
    }

    // Login to the account
    static async login() {
        sessionStorage.removeItem(`msal.interaction.status`);
        return new Promise(async (resolve, reject) => {
            if (AzureHelper.config.authModel.loginPopup) {
                await AzureHelper.msalInstance.loginPopup(AzureHelper.loginRequest).then((data) => {
                    AzureHelper.setStorage(data);
                    AzureHelper.accounts = data.account;
                    resolve(AzureHelper.accounts);
                }).catch(function (error: any) {
                    //login failure 
                    reject(error)
                });
            } else {
                await AzureHelper.msalInstance.loginRedirect(AzureHelper.loginRequest).catch(function (error: any) {
                    //login failure 
                    reject(error)
                });
            }
        })
    }

    // get access token
    static getAccessToken(): string {
        return AzureHelper.storage.getItem(StorageItems.Token)
            ? (AzureHelper.storage.getItem(StorageItems.Token) as string)
            : "";
    }

    // Getting refresh token
    static async getRefreshAccessToken(): Promise<string> {
        if ( !AzureHelper.accounts ) {
            return "";
        }
        await AzureHelper.msalInstance.acquireTokenSilent(AzureHelper.loginRequest).then(function (response) {
            AzureHelper.setStorage(response);
            console.log("Token refreshed at", new Date());
            if ( AzureHelper.config.onTokenExpriry )
                AzureHelper.config.onTokenExpriry(false);
            return response.accessToken;
        }).catch(function (error) {
            console.warn("error", error);
            if (error instanceof InteractionRequiredAuthError) {

            }
            return "";
        });
        return "";
    }

    // Get tenant information from cache 
    static getTenant() {
        return AzureHelper.storage.getItem(StorageItems.Tenant)
            ? (AzureHelper.storage.getItem(StorageItems.Tenant) as string)
            : "";
    }

    // Logout action
    static logOut() {
        localStorage.setItem("loginInformationChanged","");
        AzureHelper.storage.removeItem(StorageItems.ExpiredON);
        AzureHelper.storage.removeItem(StorageItems.Token);
        AzureHelper.storage.removeItem(StorageItems.LoggedInUser);
        AzureHelper.storage.removeItem(StorageItems.MSALConfig);
        AzureHelper.storage.removeItem(StorageItems.ShowLanding);
        AzureHelper.storage.removeItem(StorageItems.Tenant);
        AzureHelper.clearLogoutTimer();
        AzureHelper.msalInstance.logoutRedirect({
            postLogoutRedirectUri: AzureHelper.config.authModel.postLogoutRedirectUrl
        });
    }
}