import { AzureHelper } from "../AzureMSAL/AzureHelper";
import { AuthScheme, StorageItems } from "../interface";

export class AuthHelper {
  static keepLoginSession(): void {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.keepLoginSession();
      default:
        return;
    }
  }
  static authScheme: string;
  static init(authName: string) {
    AuthHelper.authScheme = authName;
    window.addEventListener('pageshow', (event) => {
      if (event.persisted && !AuthHelper.isUserLoggedIn()) {
        console.log("Logout detected!")
        window.location.reload();
      }
    });
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible" && !AuthHelper.isUserLoggedIn()) {
        console.log("Logout detected!")
        window.location.reload();
      }
    });
  }
  static isUserLoggedIn() {
    let token = AuthHelper.getAccessToken();
    if (!token) {
      return false;
    }
    return true;
  }
  static async getRefreshAccessToken(): Promise<string> {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.getRefreshAccessToken();
      default:
        return {} as any;
    }
  }
  static getAccessToken(): string {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.getAccessToken();
      default:
        return "";
    }
  }
  static getTenant(): string {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.getTenant();
      default:
        return "";
    }
  }

  static logOut() {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.logOut();
      default:
        return;
    }
  }

  static switchTenant(tenantName: string) {
    if (tenantName) {
      AzureHelper.logOut();
      AzureHelper.storage.setItem(StorageItems.SwitchTenantName, tenantName);
    }
  }
}
