import React from 'react';
import { AccountInfo } from "@azure/msal-browser";
import * as ABB from "@abb/abb-common-ux-react";
import { AuthConfiguration, StorageItems, AuthScheme } from "../interface";
import { AzureHelper } from './AzureHelper';

interface State {
  isLoginSuccess: boolean;
  isLoginPending: boolean;
  accounts: AccountInfo | null;
  tenant: string;
  storage: any;
  appName: string;
  errorDataSourceName: boolean;
  errorMessage: string;
  submitted: boolean;
}

class AzureLogin extends React.Component<AuthConfiguration, State> {
  azureHelper: any;
  constructor(props: any) {
    super(props);
    AzureHelper.init(this.props)
    this.state = {
      accounts: null,
      isLoginSuccess: false,
      isLoginPending: true,
      tenant: AzureHelper.getTenant() || "",
      storage: AzureHelper.storage,
      appName: props.applicationName,
      errorDataSourceName: false,
      errorMessage: props.errorMessage,
      submitted: false
    }
    this.login = this.login.bind(this);
    this.loginOIDC = this.loginOIDC.bind(this);
  }

  componentDidMount() {
    AzureHelper.getAccount().then((accounts: any) => {
      if (accounts && accounts.idTokenClaims && accounts.idTokenClaims.aud && (accounts.idTokenClaims.aud === AzureHelper.config.authModel.clientId)) {
        this.setState({ accounts: accounts, isLoginSuccess: true, isLoginPending: false })
        const idTokenClaims = accounts.idTokenClaims as any;
        localStorage.setItem("loginInformationChanged", `${accounts?.name}-${accounts?.tenantId}-${idTokenClaims?.email || "--"}-${accounts?.username}-${accounts?.localAccountId}`);
      }
      else {
        this.setState({ isLoginSuccess: false, isLoginPending: false })
        if (this.props.authType == AuthScheme.oidc) {
          this.loginOIDC();
        }
        // Switch Tenant Related Changes
        const switchTenantName = sessionStorage.getItem(StorageItems.SwitchTenantName);
        if(this.props.authType !== AuthScheme.oidc && switchTenantName) {
          this.setState({ tenant: switchTenantName });
          setTimeout(() => this.login(), 500);
        }
      }
    })
  }

  async login() {
    if (!this.state.tenant) {
      this.setState({
        errorDataSourceName: true,
      });
      return;
    }
    sessionStorage.removeItem(StorageItems.SwitchTenantName);
    this.setState({ submitted: true })
    this.state.storage.setItem(StorageItems.Tenant, this.state.tenant.trim().replace(/ /g, "-"));
    await AzureHelper.updateConfig();
    AzureHelper.login().then((data: any) => {
      this.setState({ accounts: data, submitted: false, isLoginSuccess: true })
    }).catch((data) => {
      console.error(data)
      this.setState({ errorDataSourceName: true, submitted: false })
    })
  }

  async loginOIDC() {
    this.setState({ submitted: true })
    AzureHelper.login().then((data: any) => {
      this.setState({ accounts: data, submitted: false, isLoginSuccess: true })
    }).catch((data) => {
      console.error(data)
      this.setState({ errorMessage: "Authentication error: " + data.message, submitted: false })
    })
  }

  componentWillUnmount() {
    AzureHelper.destroy();
  }

  render() {
    const loginError = "";
    return <React.Fragment>
      {this.state.isLoginPending && <>
        <div className='app-auth-loader' style={{ width: '100%', height: '100%', position: 'fixed', zIndex: 9999999, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ABB.LoadingIndicator
            type={"radial"}
            determinate={false}
            color={"blue"}
            sizeClass={"large"}
          />
        </div>
      </>}
      {this.state.isLoginSuccess && !this.state.isLoginPending && this.state.accounts &&
        <React.Fragment>
          {this.props.children}
        </React.Fragment>
      }
      {
        this.state.submitted &&
        <div className='app-auth-loader' style={{ width: '100%', height: '100%', position: 'fixed', zIndex: 9999999, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ABB.LoadingIndicator
            type={"radial"}
            determinate={false}
            color={"blue"}
            sizeClass={"large"}
          />
        </div>
      }
      {!this.state.isLoginPending && !this.state.isLoginSuccess &&
        <>
          {this.props.authType == AuthScheme.ability ?
            <ABB.AppContainer className="app-container" style={{ pointerEvents: this.state.submitted ? 'none' : 'auto' }}>
              <ABB.AppContent className="app-content">
                <ABB.AppMainContent className="app-main-content">
                  <ABB.LoginScreen
                    className="login tenantLogincls"
                    copyrightText=""
                    productName={this.state.appName}
                    onLogin={this.login}
                    showDefaultFields={false}
                    productFullName={this.state.appName}
                    customContent={() => {
                      return (
                        <React.Fragment>
                          <ABB.Input
                            validator={() =>
                              loginError
                                ? { valid: false, text: loginError }
                                : { valid: true, text: "" }
                            }
                            onKeyUp={(key) => (key.keyCode === 13 ? this.login : "")}
                            showValidationBarWhenValid={false}
                            showValidationIconWhenValid={false}
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                            style={{ width: "250px" }}
                            className={
                              this.state.errorDataSourceName
                                ? "errorDataSourceName"
                                : ""
                            }
                            value={this.state.tenant}
                            onValueChange={(newValue) =>
                              this.setState({
                                tenant: newValue,
                                errorDataSourceName: false,
                                errorMessage: "",
                              })
                            }
                            dataType="text"
                            inputAttributes={{ autoComplete: "on", name: "tenant" }}
                            label="Enter Tenant"
                            id="TenantLoginField"
                          />
                          {this.state.errorDataSourceName && (
                            <span>
                              {!this.state.tenant ? (
                                <div className="align-center">
                                  <ABB.Icon
                                    name="abb/error-circle-1"
                                    sizeClass="small"
                                    color="#f03040"
                                  />
                                  <b> Please Enter Tenant</b>
                                </div>
                              ) : (
                                <div className="align-center">
                                  <ABB.Icon
                                    name="abb/error-circle-1"
                                    sizeClass="small"
                                    color="#f03040"
                                  />
                                  <b> Login Failed </b>
                                </div>
                              )}
                            </span>
                          )}
                          {this.state.errorMessage && (
                            <span>
                              <div className="align-center">
                                <ABB.Icon
                                  name="abb/error-circle-1"
                                  sizeClass="small"
                                  color="#f03040"
                                />
                                <b> {this.state.errorMessage} </b>
                              </div>
                            </span>
                          )}
                        </React.Fragment>
                      );
                    }}
                  />
                </ABB.AppMainContent>
              </ABB.AppContent>
            </ABB.AppContainer>
            : <>
              {this.state.errorMessage && (
                <span>
                  <div className="align-center" style={{ color: 'red' }}>
                    <ABB.Icon
                      name="abb/error-circle-1"
                      sizeClass="small"
                      color="#f03040"
                    />
                    <b> {this.state.errorMessage} </b>
                  </div>
                </span>
              )}
            </>}
        </>
      }
    </React.Fragment>
  }
}
export default AzureLogin