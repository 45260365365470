import React from "react"
import { AuthHelper } from "./AuthHelper";
import * as ABB from "@abb/abb-common-ux-react";
export class Loader extends React.Component<any,any>{
    constructor(props){
        super(props)
        this.state = {
            isTokenRecieved:false
        }
        this.initToken();
    }
    async initToken(){
        await AuthHelper.getRefreshAccessToken();
        this.setState({
            isTokenRecieved:true
        })
    }

    render(){
        return (
            <React.Fragment>
                {
                    this.state.isTokenRecieved &&
                    (
                        <React.Fragment>
                            {this.props.children}
                        </React.Fragment>
                    )
                }
                {
                    !this.state.isTokenRecieved &&
                    (
                        <div className='app-auth-loader' style={{ width: '100%', height: '100%', position: 'fixed', zIndex: 9999999, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ABB.LoadingIndicator
                                type={"radial"}
                                determinate={false}
                                color={"blue"}
                                sizeClass={"large"}
                            />
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}